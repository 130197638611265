import clsx from 'clsx';
import React from 'react';

const Jeton = ({ className }) => (
	<div
		className={clsx(
			'absolute w-4 h-4 rounded-full border-1 border-dotted border-white bg-red-800 shadow-md shadow-gray-800',
			className,
		)}
	/>
);

const JetonsStack = () => (
	<div className="relative w-8 h-8 mx-auto mb-1">
		<Jeton className="top-0 !bg-amber-500" />
		<Jeton className="top-4 !bg-zinc-700" />
		<Jeton className="top-2 left-4" />
	</div>
);

const JetonsStake = () => (
	<div className="relative w-4 h-4 mx-auto mb-1">
		<Jeton className="top-0" />
		<Jeton className="top-1" />
	</div>
);

const JetonsBank = () => (
	<div className="relative w-8 h-14 mx-auto mb-1">
		<Jeton className="top-0 !bg-amber-500" />
		<Jeton className="top-1 !bg-amber-500" />
		<Jeton className="top-2 !bg-amber-500" />
		<Jeton className="top-6 !bg-zinc-700" />
		<Jeton className="top-7 !bg-zinc-700" />
		<Jeton className="top-8 !bg-zinc-700" />
		<Jeton className="top-0 left-4" />
		<Jeton className="top-1 left-4" />
		<Jeton className="top-2 left-4" />
		<Jeton className="top-6 left-4 !bg-sky-700" />
		<Jeton className="top-7 left-4 !bg-sky-700" />
		<Jeton className="top-8 left-4 !bg-sky-700" />
	</div>
);

export { Jeton, JetonsStake, JetonsStack, JetonsBank };
