import { Form } from 'antd';
import clsx from 'clsx';
import React from 'react';
import dealerJeton from '../../../../images/dealer.png';

const DealerFormItem = ({ classNamePosition, position }) => (
	<Form.Item shouldUpdate className={clsx('!m-0', classNamePosition)}>
		{(form) => {
			const activeDealerPosition = form.getFieldValue('dealerPosition');

			const handleChangeDealer = () => {
				if (activeDealerPosition !== position) {
					form.setFieldsValue({ dealerPosition: position });
				}
			};

			return (
				<img
					src={dealerJeton}
					alt="dealerJeton"
					className={clsx('w-8 h-8 opacity-20 cursor-pointer', {
						'opacity-100': activeDealerPosition === position,
					})}
					onClick={handleChangeDealer}
				/>
			);
		}}
	</Form.Item>
);

export default DealerFormItem;
