import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { IUserGame, GamesApi } from '../services/api/Users';
import { TABLE_PER_PAGE } from '../utils/constants';
import { createTableData, createTableDataDefault } from '../utils/createTableData';

class UserGamesStore {
	tableData = createTableDataDefault<IUserGame>();

	constructor() {
		makeAutoObservable(this);
		createTableData(this, GamesApi.getAll);
	}
}

export const userGamesStore = new UserGamesStore();
export const userGamesStoreContext = createContext(userGamesStore);
