import { SyncOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React, { useMemo } from 'react';
import { ResultTypeColor, getDecisionText } from '../../../../utils/gameParameters';
import { ResultDecision } from '../../../../services/api/Users';
import clsx from 'clsx';

interface GameResultProps {
	loading: boolean;
	results: ResultDecision[];
}

const GameResult: React.FC<GameResultProps> = ({ loading, results }) => {
	if (loading) {
		return (
			<Tag className="!h-full !leading-8" icon={<SyncOutlined spin />} color="processing">
				Data processing
			</Tag>
		);
	}

	if (!results)
		return (
			<Tag className="!flex items-center font-bold !h-full px-3" color="default">
				-
			</Tag>
		);

	const maxProfit = useMemo(
		() => results.reduce((acc, result) => Math.max(result.profit, acc), results.length ? results[0].profit : 0),
		[],
	);

	return (
		<div className="flex gap-2 !h-full">
			{results
				.sort((a, b) => b.profit - a.profit)
				.map((result, index) => (
					<Tag
						key={index}
						className={clsx('!flex items-center px-3', {
							'font-bold': result.profit === maxProfit,
						})}
						color={result.profit === maxProfit ? ResultTypeColor[result.decision] : undefined}
					>
						{getDecisionText(result)}
						{` ${result.profit.toFixed(2)} BB`}
					</Tag>
				))}
		</div>
	);
};

export default GameResult;
