import React from 'react';
import PokerCardSelector from '../../../components/PokerCardSelector/PokerCardSelector';

interface Props<T> {
	cardsCount: number;
	value?: T;
	onChange?: (value: T) => void;
	disableCards?: string[];
}

const CardsField = <T extends string[]>({
	cardsCount,
	value = new Array(cardsCount).fill('') as T,
	onChange = () => {},
	disableCards = [],
}: Props<T>) => {
	const handleCardChange = (cardValue: string, indexCard: number) => {
		const newCards = [...value];
		newCards[indexCard] = cardValue;
		onChange(newCards as T);
	};

	return (
		<div className="flex gap-2">
			{value.map((_, indexCard) => (
				<PokerCardSelector
					key={indexCard}
					cardName={value[indexCard]}
					disableCards={[...disableCards, ...value].filter((card) => card.length > 0)}
					handleCardChange={(cardValue) => handleCardChange(cardValue, indexCard)}
				/>
			))}
		</div>
	);
};

export default CardsField;
