import { CloseOutlined } from '@ant-design/icons';
import { Dropdown, Radio } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_SUIT, getSuitClassColor, ranks, Suits, SuitsIcons } from '../../utils/gameParameters';
import PokerCard from '../PokerCard/PokerCard';

interface PokerCardSelector {
	cardName?: string;
	handleCardChange: (value: string) => void;
	disableCards?: string[];
}

const PokerCardSelector: React.FC<PokerCardSelector> = ({ cardName = '', handleCardChange, disableCards = [] }) => {
	const rankInit = cardName.slice(0, cardName.length - 1);
	const suitInit = cardName.slice(-1);

	const wrapperRef = useRef(null);
	const [suit, setSuit] = useState(suitInit ? suitInit : DEFAULT_SUIT);
	const [visible, setVisible] = useState(false);

	const activeRanksSuit = useMemo(
		() => ranks.filter((rank) => !disableCards.includes(rank + suit)),
		[suit, disableCards],
	);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !(wrapperRef.current as any).contains(event.target)) {
				setVisible(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [wrapperRef]);

	const handleCardClick = (rank: string) => {
		setVisible(false);
		handleCardChange(rank + suit);
	};

	const handleClear = () => {
		setVisible(false);
		handleCardChange('');
	};

	const overlay = (
		<div
			className={clsx('max-w-screen-sm bg-white p-5 shadow-lg border border-solid rounded border-slate-100')}
			ref={wrapperRef}
		>
			<Radio.Group style={{ marginBottom: 15 }} value={suit} onChange={(e) => setSuit(e.target.value)}>
				{Object.keys(Suits).map((suit) => (
					<Radio key={suit} value={suit}>
						<div className={getSuitClassColor(suit)}>{SuitsIcons[suit]}</div>
					</Radio>
				))}
			</Radio.Group>

			<div className="grid grid-cols-7 gap-1">
				{activeRanksSuit.map((rank) => (
					<div key={rank + suit} className="cursor-pointer" onClick={() => handleCardClick(rank)}>
						<PokerCard suit={suit} rank={rank} selected={rank === rankInit} />
					</div>
				))}
				<div className="cursor-pointer relative z-10" onClick={handleClear}>
					<PokerCard />
					<CloseOutlined className="absolute top-8 left-0 right-0 text-red-800 text-3xl" />
				</div>
			</div>
		</div>
	);

	return (
		<Dropdown visible={visible} overlay={overlay}>
			<div onClick={() => setVisible(true)} className="cursor-pointer">
				<PokerCard className={rankInit ? '!bg-white' : ''} suit={suitInit} rank={rankInit} />
			</div>
		</Dropdown>
	);
};

export default PokerCardSelector;
