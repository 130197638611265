export const selectElementText = (el: HTMLElement) => {
	let doc = window.document;
	let range;
	let selection;

	if (window.getSelection && doc.createRange) {
		selection = window.getSelection();
		range = doc.createRange();
		range.selectNodeContents(el);
		selection.removeAllRanges();
		selection.addRange(range);
	}
};
