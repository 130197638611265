import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import AppTable from '../../../components/tables/AppTable/AppTable';
import { userGamesStoreContext } from '../../../stores/UserGamesStore';

interface Props {}

const columns = [
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
		sorter: true,
	},
	{
		title: 'Count',
		dataIndex: 'requestsCount',
		key: 'requestsCount',
		sorter: true,
		align: 'right' as const,
	},
];

const UserGamesTable = (props: Props) => {
	const { tableData } = useContext(userGamesStoreContext);

	useEffect(() => {
		tableData.fetch();
	}, []);

	const handleTableChange = (pagination, filters, sorter) => {
		tableData.setPage(pagination.current);
		tableData.setSorter(sorter);
	};

	return <AppTable rowKey="date" columns={columns} dataSource={tableData.dataSource} onChange={handleTableChange} />;
};

export default observer(UserGamesTable);
