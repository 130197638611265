import { Form, InputNumber } from 'antd';
import clsx from 'clsx';
import React from 'react';

type Props = {
	wrapperClassName: string;
	preffics: React.ReactNode;
	placeholder?: string;
	min?: number;
	max?: number;
	fieldProps: { [n: string]: unknown };
};

const NumberAbsoluteFormItem = ({ fieldProps, wrapperClassName, preffics, placeholder, min = 0, max }: Props) => (
	<div className={clsx('absolute', wrapperClassName)}>
		{preffics}
		<Form.Item {...fieldProps} className="!mb-0 !ml-1">
			<InputNumber
				size="small"
				placeholder={placeholder}
				controls={false}
				min={min}
				max={max}
				className="!bg-black/10 !w-14 !text-white"
			/>
		</Form.Item>
	</div>
);

export default NumberAbsoluteFormItem;
