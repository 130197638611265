import clsx from 'clsx';
import React from 'react';
import { getSuitClassColor, SuitsIcons } from '../../utils/gameParameters';

interface PokerCardProps {
	rank?: string;
	suit?: string;
	selected?: boolean;
	className?: string;
}

const PokerCard: React.FC<PokerCardProps> = ({ rank, suit, selected, className }) => (
	<div
		className={clsx(
			'border-2 border-solid w-16 h-24 grid relative place-content-center rounded bg-slate-100  hover:border-blue-800 border-transparent',
			getSuitClassColor(suit),
			selected && 'border-blue-800 shadow-md shadow-blue-800',
			className,
			!rank && 'bg-poker-card bg-no-repeat bg-cover bg-center',
		)}
	>
		<div className="absolute top-1 left-2">{rank}</div>
		<div className="text-2xl">{suit && SuitsIcons[suit]}</div>
		<div className="absolute bottom-1 right-2 rotate-180">{rank}</div>
	</div>
);

export default PokerCard;
