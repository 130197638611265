import { Button } from 'antd';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { copyToClipboard } from '../utils/copyToClipboard';
import { selectElementText } from '../utils/selectElementText';

interface Props {
	json: unknown;
}

const CodeSnippet = ({ json }: Props) => {
	const [copied, setCopied] = useState(false);
	const codeRef = useRef<HTMLPreElement>(null);

	const handleCopy = () => {
		copyToClipboard(JSON.stringify(json));
		setCopied(true);
		if (codeRef?.current) selectElementText(codeRef.current);
	};

	return (
		<pre ref={codeRef} className={clsx('flex w-full p-2 relative border border-blue-500 border-dashed')}>
			{JSON.stringify(json, null, 4)}
			<Button onClick={handleCopy} className="!absolute bottom-2 right-2" type="primary">
				{copied ? 'Copied' : 'Copy'}
			</Button>
		</pre>
	);
};

export default CodeSnippet;
