import { InfoOutlined } from '@ant-design/icons';
import { Button, Tabs, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import CodeSnippet from '../../components/CodeSnippet';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import GameForm from '../../modules/User/Games/GameForm/GameForm';
import UserGamesTable from '../../modules/User/Games/UserGamesTable';
import { userStoreContext } from '../../stores/UserStore';
import { Roles } from '../../utils/constants';
import { getJSONApiParameters } from '../../utils/gameParameters';
import { checkIsClient } from '../../utils/checkIsClient';

const { TabPane } = Tabs;

interface Props {}

const Games = (props: Props) => {
	const { user } = useContext(userStoreContext);
	const jsonExample = getJSONApiParameters(user?.email);

	return (
		<>
			<Helmet>
				<title>History of Games</title>
			</Helmet>
			<div className="page">
				<h1 className="page__title">Games</h1>

				<Tabs
					tabBarExtraContent={{
						left: (
							<Tooltip
								title={
									<p>
										The AI-RPM (Request Processing Machine) on the input gets a description of the
										current situation. The situation is described by the chosen template. As a
										response, the system gives a variant of the best move.
									</p>
								}
								placement="bottom"
							>
								<Button className="mr-4 -mb-4" type="dashed" shape="circle" icon={<InfoOutlined />} />
							</Tooltip>
						),
					}}
					defaultActiveKey="1"
				>
					<TabPane tab="Game" key="1">
						<GameForm />
					</TabPane>
					<TabPane tab="API Integration" key="2">
						<div className="p-4">
							<div className="bg-blue-100 -m-4 rounded-sm p-4">
								<p className="mb-2">
									Make requests to:{' '}
									<span className="font-bold">
										{checkIsClient() && window.location.host}/api/games/play
									</span>
								</p>

								<p className="font-bold">Headers</p>
								<p className="mb-2">
									Authorization: Bearer{' '}
									<span className="font-bold text-gray-500">{'{ access_token }'}</span>
								</p>
								<p className="font-bold">Body</p>
								<CodeSnippet json={jsonExample} />
							</div>
						</div>
					</TabPane>
					<TabPane tab="History" key="3">
						<UserGamesTable />
					</TabPane>
				</Tabs>
			</div>
		</>
	);
};

export default withPrivateRoute(Games, [Roles.admin, Roles.user]);
