import { Form, InputNumber } from 'antd';
import React from 'react';

type Props = {
	name: string | number;
	label: string;
	min?: number;
	max?: number;
	fieldProps: { [n: string]: unknown } | {};
};

const PlayerFormItem = ({ fieldProps, name, label, min, max }: Props) => (
	<Form.Item {...fieldProps} name={[name, label]} className="!mb-0 !ml-1">
		<InputNumber
			size="small"
			placeholder={label}
			controls={false}
			min={min}
			max={max}
			className="!w-14 !bg-black/40 !text-white"
		/>
	</Form.Item>
);

export default PlayerFormItem;
