import clsx from 'clsx';
import React from 'react';

const BgClassColor = [
	'bg-red-300 border-red-500',
	'bg-yellow-400 border-yellow-600',
	'bg-orange-300 border-orange-500',
	'bg-teal-300 border-teal-500',
	'bg-blue-300 border-blue-500',
];

const PlayerAvatar = ({ children, index }) => (
	<div
		className={clsx(
			'w-32 h-32 flex flex-col justify-center items-center rounded-full border-8 border-solid',
			BgClassColor[index],
		)}
	>
		{children}
	</div>
);

export default PlayerAvatar;
