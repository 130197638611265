export const validatorTableCards = () => ({
	validator(_, value) {
		if (!value) return Promise.resolve();

		const currentCards = value.filter((v) => v.length > 0);

		if (currentCards.length > 0 && currentCards.length < 3) {
			return Promise.reject(new Error('3, 4, 5 cards or none'));
		}

		return Promise.resolve();
	},
});

export const validatorHandCards = () => ({
	validator(_, value) {
		if (!value) return Promise.resolve();

		if (!(value[0] === '' && value[1] === '') && !value.reduce((acc, val) => acc && val.length > 0, true)) {
			return Promise.reject(new Error('Two cards or none'));
		}

		return Promise.resolve();
	},
});
