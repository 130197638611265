import React from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

type Props = {
	children: React.ReactNode;
	visible: boolean;
	fieldName: string | number;
	placement?: TooltipPlacement;
};

const tooltipTitle = {
	stake: 'The amount of money they are willing to play. 0-200 BB',
	stack: 'The entire amount of chips. 0-200 BB',
	dealer: 'The marker used to indicate the player who is dealing',
	handCards: 'Your cards. Must be empty or two cards',
	tableCards: 'Table cards. Must be empty or 3,4,5 cards',
	bank: 'Bank size in BB',
	vpip: 'Players VPIP. 0-100',
	pfr: 'Players PFR. 0-100',
	tableSize: 'Number of players. 2-6',
	result: 'Your hint',
};

const OnboardingTooltip = ({ children, visible, fieldName, placement }: Props) => (
	<Tooltip title={tooltipTitle[fieldName]} visible={visible} placement={placement}>
		{children}
	</Tooltip>
);

export default OnboardingTooltip;
