import { Button, Form, Select } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import { GamesApi, ResultDecision } from '../../../../services/api/Users';
import { TABLE_SIZES } from '../../../../utils/gameParameters';
import GameResult from './GameResult';
import CardsField from '../CardsField';
import PlayerFormItem from './PlayerFormItem';
import NumberAbsoluteFormItem from './NumberAbsoluteFormItem';
import DealerFormItem from './DealerFormItem';
import PlayerAvatar from './PlayerAvatar';
import OnboardingTooltip from './OnboardingTooltip';
import { JetonsStake, JetonsStack, JetonsBank } from './Jetons';
import { validatorTableCards, validatorHandCards } from './helper';
import './GameForm.scss';

const INITIAL_FORM = {
	tableSize: 2,
	players: [{}],
	dealerPosition: 0,
	tableCards: ['', '', '', '', ''],
	handCards: ['', ''],
};
const REQUIRED_RULE = { required: true, message: false };

const GameForm = () => {
	const [form] = Form.useForm();
	const [result, setResult] = useState<ResultDecision[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [showOboarding, setShowOboarding] = useState<boolean>(false);

	const handlePlayGame = (values) => {
		const valueToSend = { ...values, dealerPosition: form.getFieldValue('dealerPosition') };
		setLoading(true);
		setResult([]);

		GamesApi.playGame(valueToSend)
			.then((result) => {
				setResult(result);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChangeTableSize = (tableSize) => {
		let players = form.getFieldValue('players') || [];
		const sizeWithoutHero = tableSize - 1;

		if (players.length > sizeWithoutHero) {
			players.splice(sizeWithoutHero);
		} else {
			const newFileds = new Array(sizeWithoutHero - players.length).fill({});
			players = [...players, ...newFileds];
		}
		form.setFieldsValue({ players });
	};

	return (
		<Form
			form={form}
			initialValues={INITIAL_FORM}
			layout="horizontal"
			className="w-full overflow-auto game-form"
			onFinish={handlePlayGame}
		>
			<div className="w-full flex">
				<Form.Item>
					<Button
						onClick={() => setShowOboarding(!showOboarding)}
						className="mr-4 -mb-4 cursor-pointer"
						type="dashed"
						shape="circle"
						icon={<QuestionOutlined />}
					/>
				</Form.Item>

				<OnboardingTooltip visible={showOboarding} fieldName="tableSize" placement="bottom">
					<Form.Item label="Table size" name="tableSize" className="max-w-xs">
						<Select onChange={handleChangeTableSize}>
							{TABLE_SIZES.map((talbeSize) => (
								<Select.Option key={talbeSize} value={talbeSize}>
									{talbeSize}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</OnboardingTooltip>

				<div className="flex ml-16">
					<Button loading={loading} type="primary" htmlType="submit">
						Play
					</Button>
					<span className="h-8 ml-2">
						<OnboardingTooltip visible={showOboarding} fieldName="result" placement="bottom">
							<GameResult loading={loading} results={result} />
						</OnboardingTooltip>
					</span>
				</div>
			</div>

			<div className="m-10 bg-green-600 border-[20px] border-green-700 border-solid rounded-[100px] min-h-[500px] w-[850px] relative">
				<div className="absolute flex align-middle top-[50%] left-[50%] -translate-x-1/2 -translate-y-[40%]">
					<OnboardingTooltip visible={showOboarding} fieldName="tableCards">
						<Form.Item shouldUpdate>
							{() => (
								<Form.Item name="tableCards" rules={[validatorTableCards]}>
									<CardsField<[string, string, string, string, string]>
										cardsCount={5}
										disableCards={form.getFieldValue('handCards')}
									/>
								</Form.Item>
							)}
						</Form.Item>
					</OnboardingTooltip>

					<div>
						<OnboardingTooltip visible={showOboarding} fieldName="bank">
							<NumberAbsoluteFormItem
								fieldProps={{ name: 'bank', rules: [REQUIRED_RULE] }}
								wrapperClassName="!ml-2"
								preffics={<JetonsBank />}
								placeholder="bank"
							/>
						</OnboardingTooltip>
					</div>
				</div>

				<div className="absolute bottom-0 left-[50%] translate-y-3 -translate-x-1/2">
					<div className="relative pl-16">
						<div className="rounded-lg bg-gray-300/70 p-2">
							<div className="text-center">You</div>
							<OnboardingTooltip visible={showOboarding} fieldName="handCards" placement="bottomLeft">
								<Form.Item shouldUpdate>
									{() => (
										<Form.Item name="handCards" className="!mb-1" rules={[validatorHandCards]}>
											<CardsField<[string, string]>
												cardsCount={2}
												disableCards={form.getFieldValue('tableCards')}
											/>
										</Form.Item>
									)}
								</Form.Item>
							</OnboardingTooltip>
						</div>

						<OnboardingTooltip visible={showOboarding} fieldName="stake">
							<NumberAbsoluteFormItem
								fieldProps={{ name: 'heroStake', rules: [REQUIRED_RULE] }}
								wrapperClassName="top-0 left-0"
								preffics={<JetonsStake />}
								placeholder="stake"
								min={0}
								max={200}
							/>
						</OnboardingTooltip>

						<OnboardingTooltip visible={showOboarding} fieldName="stack">
							<NumberAbsoluteFormItem
								fieldProps={{ name: 'heroStack', rules: [REQUIRED_RULE] }}
								wrapperClassName="bottom-0 left-0"
								preffics={<JetonsStack />}
								placeholder="stack"
								min={0}
								max={200}
							/>
						</OnboardingTooltip>

						<OnboardingTooltip visible={showOboarding} fieldName="dealer" placement="bottomLeft">
							<DealerFormItem
								classNamePosition={clsx('absolute right-0 top-0 translate-x-1/2 -translate-y-1/2')}
								position={0}
							/>
						</OnboardingTooltip>
					</div>
				</div>

				<Form.List name="players">
					{(fields) => (
						<>
							{fields.map(({ key, name, ...restField }, index) => (
								<div
									key={key}
									className={clsx('absolute', {
										'bottom-[10%] left-[10%] -translate-x-1/2 translate-y-1/2': index === 0,
										'top-[10%] left-[10%] -translate-x-1/2 -translate-y-1/2': index === 1,
										'top-[5%] left-[50%] -translate-x-1/2 -translate-y-1/2': index === 2,
										'top-[10%] right-[10%] translate-x-1/2 -translate-y-1/2': index === 3,
										'bottom-[10%] right-[10%] translate-x-1/2 translate-y-1/2': index === 4,
									})}
								>
									<div
										className={clsx('relative', {
											'pr-16 pt-10': index === 0,
											'pr-16 pb-10': index === 1,
											'pl-16 pb-10': index === 3,
											'pl-16 pt-10': index === 4,
											'pb-10 pr-16': index === 2,
										})}
									>
										<PlayerAvatar index={index}>
											<div>{`Player ${index + 2}`}</div>
											<div className="!grid grid-col">
												<OnboardingTooltip
													visible={index === 0 && showOboarding}
													fieldName="vpip"
												>
													<PlayerFormItem
														fieldProps={restField}
														name={name}
														label="vpip"
														min={0}
														max={100}
													/>
												</OnboardingTooltip>

												<OnboardingTooltip
													visible={index === 0 && showOboarding}
													fieldName="pfr"
													placement="bottom"
												>
													<PlayerFormItem
														fieldProps={restField}
														name={name}
														label="pfr"
														min={0}
														max={100}
													/>
												</OnboardingTooltip>
											</div>
										</PlayerAvatar>

										<NumberAbsoluteFormItem
											fieldProps={{
												...restField,
												name: [name, 'stake'],
												rules: [{ required: true, message: '' }],
											}}
											wrapperClassName={clsx('!m-0 absolute', {
												'top-0 right-[15%]': index === 0,
												'bottom-0 right-[15%]': index === 1 || index === 2,
												'bottom-0 left-[15%]': index === 3,
												'top-0 left-[15%]': index === 4,
											})}
											preffics={<JetonsStake />}
											placeholder="stake"
											min={0}
											max={200}
										/>

										<NumberAbsoluteFormItem
											fieldProps={{
												...restField,
												name: [name, 'stack'],
												rules: [REQUIRED_RULE],
											}}
											wrapperClassName={clsx('!m-0 absolute', {
												'bottom-0 right-0 -translate-y-1/2': index === 0,
												'top-0 right-0 translate-y-1/2': index === 1,
												'top-[5%] right-0 translate-y-1/2': index === 2,
												'top-0 left-0 translate-y-1/2': index === 3,
												'bottom-0 left-0 -translate-y-1/2': index === 4,
											})}
											preffics={<JetonsStack />}
											placeholder="stack"
											min={0}
											max={200}
										/>

										<DealerFormItem
											position={index + 1}
											classNamePosition={clsx('absolute', {
												'right-0 top-0 translate-x-1/2 -translate-y-1/2': index === 0,
												'right-0 bottom-0 translate-x-1/2 -translate-y-1/2': index === 1,
												'right-1/2 bottom-0 -translate-x-1/2 ': index === 2,
												'left-0 bottom-0 -translate-x-1/2 -translate-y-1/2': index === 3,
												'left-0 top-0 -translate-x-1/2 -translate-y-1/2': index === 4,
											})}
										/>
									</div>
								</div>
							))}
						</>
					)}
				</Form.List>
			</div>
		</Form>
	);
};

export default GameForm;
