export enum Suits {
	s = 'spades',
	h = 'hearts ',
	d = 'diamonds',
	c = 'clubs',
}

export enum SuitsIcons {
	s = '♠︎',
	h = '♥︎',
	d = '♦︎',
	c = '♣︎',
}

export const DEFAULT_SUIT = 's';

export const getSuitClassColor = (suit) => (suit === 's' || suit === 'c' ? 'text-stone-800' : 'text-red-700');

export const ranks = '2 3 4 5 6 7 8 9 10 J Q K A'.split(' ');

export const TABLE_SIZES = new Array(5).fill(2).map((size, index) => size + index);
export const DEALER_DISTANCES = new Array(9).fill(0).map((_, index) => index);

export const getJSONApiParameters = (email) => ({
	bank: 222,
	dealerPosition: 0,
	handCards: ['As', '6s'],
	heroStack: 110,
	heroStake: 20,
	players: [
		{ stake: 10, stack: 150 },
		{ vpip: 50, pfr: 60, stake: 20, stack: 100 },
	],
	tableCards: ['5s', '5c', 'Qd', '', ''],
	tableSize: 3,
	auth: {
		email: email,
		password: 'Your password',
	},
});

export enum ResultTypeColor {
	fold = 'error',
	call = 'warning',
	raise = 'success',
}

export const getDecisionText = (result) => {
	const decision = result.decision.toLocaleUpperCase();

	if (result.value === 0) {
		return `${decision}:`;
	}

	return `${decision} ${result.value} BB:`;
};
